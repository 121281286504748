/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Defines the approval status for documents. */
export enum ApprovalStatus {
  Value = "",
  Approved = "Approved",
  Rejected = "Rejected",
}

/** Arrival Information gathered together. REQUIRED when direction is Outbound */
export interface ArrivalInfo {
  /**
   * Arrival Terminal is REQUIRED only for 'Flight' travel type.
   * @example "3"
   */
  arrivalTerminal?: string | null;
  /**
   * Arrival Gateway is REQUIRED only for 'Flight' travel type.
   * @example "MIA"
   */
  arrivalGateway?: string | null;
  /**
   * Arrival Date in format yyyy-mm-dd
   * @format date
   * @example "2023-07-14"
   */
  arrivalDate?: string | null;
  /**
   * Arrival Time is in UTC and in format HH:mm:ss
   * @format time
   * @example "17:53:00"
   */
  arrivalTime?: string | null;
}

/** Bookings connected to the travel itinerary */
export interface BookingResponse {
  /**
   * Id of booking
   * @example "1a4e8516-9ac1-409d-bbea-1c29e20fcb99"
   */
  id?: string | null;
  /**
   * Id of group (can be empty)
   * @example "8185e637-afba-4dc0-80f2-f9da4586d7d4"
   */
  groupId?: string | null;
}

/** Status of created booking links */
export interface BulkCreateTravelItineraryResponse {
  /**
   * Identifier of batch or bulk request item
   * @example "aafb4b92-ad12-45e2-b82c-c514b26e3ae5"
   */
  id?: string | null;
  items?: BulkCreateTravelItineraryResponseItem[] | null;
}

/** An item of travel itinerary creation */
export interface BulkCreateTravelItineraryResponseItem {
  /**
   * Identifier of Booking
   * @example "bae446cf-2f13-4e88-a5fd-d305395fece1"
   */
  bookingId?: string | null;
  /** @example "CREATED" */
  status?: string | null;
  message?: string | null;
}

/** Represents an item in a checklist, including details such as status, deadlines, and associated documents. */
export interface ChecklistItem {
  /** The unique identifier for this checklist item. */
  id?: string | null;
  /**
   * The date and time when the checklist item was created in Salesforce.
   * @format date-time
   * @example "2022-09-29T14:38:38"
   */
  createdAt?: string | null;
  /**
   * The date by which information is expected from the customer.
   * @format date-time
   * @example "2022-09-29"
   */
  dueDate?: string | null;
  /** Defines the status of a checklist item. */
  status?: ChecklistItemStatus;
  /**
   * A URI link for the customer to preview or sign the document associated with this checklist item.
   * @example "https://eflang--qa.my.salesforce.com/sfc/p/5r0000004jB5/a/5r0000001152/zjiie1eUCWBglUw3_HGDeZJZ71WP_J9XWaJ4d.HnWn0"
   */
  documentForCustomer?: string | null;
  /**
   * Indicates whether the checklist item should be visible to the School.
   * @example false
   */
  shareWithProduction?: boolean;
  /**
   * Indicates whether the checklist item should be shown on the Student Portal.
   * @example true
   */
  showInStudentPortal?: boolean;
  /**
   * The name of the checklist to which this item is attached, intended for Sales Office use.
   * @example "Vaccination Proof"
   */
  name?: string | null;
  /** The type of document expected for this checklist item. */
  documentType?: string | null;
  /** The category that describes what is expected of the customer with regard to this checklist item. */
  category?: string | null;
  /**
   * The title of the checklist item as displayed to the customer in the Student Portal, written in the local language.
   * @example "Vaccination Proof"
   */
  titleInStudentPortal?: string | null;
  /**
   * The secondary title for the checklist item in the Student Portal.
   * @example "Please provide best possible quality picture"
   */
  subtitleInStudentPortal?: string | null;
  /** The list of links to documents related to this checklist item. */
  documentLinks?: DocumentLink[] | null;
}

/** Defines the status of a checklist item. */
export enum ChecklistItemStatus {
  Preparing = "Preparing",
  WaitingForDocumentGeneration = "Waiting for Document Generation",
  New = "New",
  SalesToApprove = "Sales to Approve",
  SchoolToApprove = "School to Approve",
  DocumentRejected = "Document Rejected",
  RejectedBySchool = "Rejected by School",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

/** Response containing a list of checklist items. */
export interface ChecklistItemsResponse {
  /** List of checklist items. */
  items?: ChecklistItem[] | null;
}

/** Represents the communication details for a customer, including a referral URL. */
export interface CommunicationDetails {
  referralURL?: string | null;
}

/** Request to create a new document link. */
export interface CreateDocumentLinkRequest {
  /**
   * The name of the Document in Salesforce, which is generated using the name of the file uploaded by the customer.
   * @minLength 1
   * @maxLength 80
   */
  name: string;
  /** Unique identifier for the document record in the document storage service. */
  documentId?: string | null;
  /** Identifier for the checklist item associated with this document link. */
  checklistItemId?: string | null;
  /**
   * File extension of the document.
   * @example ".pdf"
   */
  fileExtension?: string | null;
}

/** Response after creating a new document link. */
export interface CreateDocumentLinkResponse {
  /** The identifier for the newly created document link. */
  id?: string | null;
}

/** Connects Group and related bookings to travel itinerary */
export interface CreateGroupTravelItineraryRequest {
  /**
   * List of booking ids that are part of the group to connect to travel itinerary
   * @example ["d16792e9-b10f-4f6c-a0d3-64e20fdfb1c5","8ff0e41a-57b8-4f65-9bd7-5b896f139d67"]
   */
  bookingIds?: string[] | null;
}

/** Http request for the creation of a new related contact. */
export interface CreateRelatedContactRequest {
  /** Gets or sets the first name of the related contact. */
  firstName: string;
  /** Gets or sets the last name of the related contact. */
  lastName: string;
  /**
   * Gets or sets the email of the related contact.
   * @format email
   */
  email: string;
  /** Gets or sets the phone number of the related contact. */
  phone: string;
  role?: CustomerRelationship;
  /** Gets or sets a value indicating whether the related contact is an emergency contact. */
  isEmergencyContact: boolean;
}

/** Service Request creation response */
export interface CreateServiceRequestRequest {
  /**
   * Booking number related to the request
   * @minLength 1
   * @maxLength 20
   * @pattern \w{3}\d{2,}
   * @example "DEB232424"
   */
  bookingNumber: string;
  /**
   * Subject of the request
   * @minLength 1
   * @maxLength 255
   * @example "more inforamtion"
   */
  subject: string;
  /**
   * Details of the request. Use it to provide more context to the request
   * @minLength 1
   * @maxLength 32000
   * @example "more inforamtion"
   */
  description: string;
  /**
   * Type of request out of defined set of values. COURSE_EXTENSION - for all
   * cases when a student would like to extend the course. CUSTOMER_INFORMATION_UPDATE - any request related to updating
   * personal information. CUSTOMER_FINANCES - requests related to financial matters . CUSTOMER_TRAVEL - requests
   * related to travel details and travel arrangements. CUSTOMER_DOCUMENTS - requests related to correctness of the
   * documentation.
   */
  type: ServiceRequestType;
}

/** Post Travel Itinerary Request */
export interface CreateTravelItineraryRequest {
  /** Describes type of travel. */
  travelType: TravelItineraryType;
  /** Travel Itinerary Type classifies the itinerary based on its geographical scope, INTL - International, INTLDOM - International and Domestic, DOM - Domestic */
  type?: TravelType;
  /** Status of the resource in salesforce */
  status?: Status;
  /**
   * Required when travel type is 'Flight'. Alphanumeric code used to identify and access a specific record on an airline's reservation
   * @example "WWEABC"
   */
  recordLocator?: string | null;
  /**
   * Number of seats that are assigned to this Travel Itinerary.
   * @format int32
   * @example 10
   */
  seats?: number | null;
  /**
   * Description of the meeting point for groups.
   * @example "Terminal T1, piers A."
   */
  meetingPoint?: string | null;
  /**
   * The time you need to be at the departure point. Time is in UTC.
   * @example "13:30:00"
   */
  meetingTime?: string | null;
  /** Required when itinerary is 'Private' Designed to capture the means of travel for a student in the context of private travel arrangements. */
  meansOfTravel?: MeansOfTravel;
  /**
   * DEPRECATED USE LINKS INSTEAD - Bookings that will be connected to created Travel Itinerary
   * @deprecated
   * @example ["d16792e9-b10f-4f6c-a0d3-64e20fdfb1c5"]
   */
  bookingIds?: string[] | null;
  /** Bookings and groups to connect to travel itinerary */
  links?: TravelItineraryLinks[] | null;
}

/** Post Travel Leg Request */
export interface CreateTravelLegRequest {
  /** Status of the resource in salesforce */
  status?: Status;
  /**
   * Travel number is REQUIRED only for 'Flight travel type.
   * @example "BA 417"
   */
  travelNumber?: string | null;
  /** Direction of the travel leg */
  direction?: TravelLegDirection;
  /** Departure Information gathered together. REQUIRED when direction is Return */
  departureInfo?: DepartureInfo;
  /** Arrival Information gathered together. REQUIRED when direction is Outbound */
  arrivalInfo?: ArrivalInfo;
  /**
   * Carrier is REQUIRED only for 'Flight' travel type.
   * @example "British Airways"
   */
  carrier?: string | null;
  /**
   * Additional information.
   * @example "This student will be arriving in London two days early and exploring the city with their parents. Then their parents will drop them directly at the school."
   */
  comments?: string | null;
}

/** Response for creating a travel itinerary/leg */
export interface CreateTravelResponse {
  /**
   * Id of creatated itinerary/leg
   * @example "caf16210-4a80-4f12-a1df-ed07e60e0f7e"
   */
  id?: string | null;
}

/** Represents a customer. */
export interface Customer {
  /** The unique identifier for this checklist item. */
  id?: string | null;
  /** The first name of the customer */
  firstName?: string | null;
  /** The middle name of the customer */
  middleName?: string | null;
  /** The last name of the customer */
  lastName?: string | null;
  /** Represents a person, including details such as name, date of birth, and contact information. */
  personDetails?: PersonDetails;
  /** Represents the market and language details of a customer. */
  marketDetails?: MarketDetails;
  /** Represents a person's english details, such as name, date of birth, and contact information. */
  latin?: Latin;
  /** A collection of the users addresses */
  addresses?: CustomersAddresses;
  /** Represents the communication details for a customer, including a referral URL. */
  communicationDetails?: CommunicationDetails;
}

/** Represents a customer's address, including details such as city, country, and postal code. */
export interface CustomerAddress {
  /** The region where the customer resides. */
  region?: string | null;
  /** The country code for the country where the customer resides. */
  countryCode?: string | null;
  /** The state where the customer resides. */
  state?: string | null;
  /** The city where the customer resides. */
  city?: string | null;
  /** The street where the customer resides. */
  street?: string | null;
  /** The postal code of the customer's residency */
  postalCode?: string | null;
}

export enum CustomerRelationship {
  Father = "Father",
  Mother = "Mother",
  Child = "Child",
  Sibling = "Sibling",
  Other = "Other",
  Guardian = "Guardian",
  SpouseCivilPartner = "Spouse/Civil partner",
}

/** A collection of the users addresses */
export interface CustomersAddresses {
  /** Represents a customer's address, including details such as city, country, and postal code. */
  mailingAddress?: CustomerAddress;
}

/** Responses for each deleted leg */
export interface DeleteTravelLegsResponse {
  items?: DeleteTravelLegsResponseItem[] | null;
}

export interface DeleteTravelLegsResponseItem {
  /**
   * Identifier of travel leg
   * @example "bae446cf-2f13-4e88-a5fd-d305395fece1"
   */
  id?: string | null;
  /** @example "CREATED" */
  status?: string | null;
  message?: string | null;
}

/** Departure Information gathered together. REQUIRED when direction is Return */
export interface DepartureInfo {
  /**
   * Departure Terminal is REQUIRED only for 'Flight' travel type.
   * @example "1"
   */
  departureTerminal?: string | null;
  /**
   * Departure Gateway is REQUIRED only for 'Flight' travel type.
   * @example "GVA"
   */
  departureGateway?: string | null;
  /**
   * Departure Date in format yyyy-mm-dd
   * @format date
   * @example "2023-07-14"
   */
  departureDate?: string | null;
  /**
   * Departure Time is in UTC and in format HH:mm:ss
   * @format time
   * @example "16:38:00"
   */
  departureTime?: string | null;
}

/** Represents a link to a document. */
export interface DocumentLink {
  /** Unique identifier for this document link. */
  id?: string | null;
  /**
   * The date the document link was created in Salesforce.
   * @format date-time
   * @example "2022-09-29T14:38:38"
   */
  createdAt?: string;
  /**
   * The name of the Document in Salesforce, which is generated using the name of the file uploaded by the customer.
   * @example "IMG02022022"
   */
  name?: string | null;
  /** Identifier for the related account in Salesforce. */
  accountId: string | null;
  /** Identifier for the booking in Salesforce. */
  bookingId: string | null;
  /**
   * Unique identifier for document record in document storage service - GUID format.
   * @example "86f6a2fe-6734-4510-9ca6-10a16442abca"
   */
  documentId: string | null;
  /** Unique identifier for the checklist item associated with this document link. */
  checklistItemId?: string | null;
  /** Type of document. */
  documentType: string | null;
  /**
   * Should this document be shown in the Student Portal?
   * @example true
   */
  showInStudentPortal?: boolean;
  /**
   * File extension of the document.
   * @example ".jpg"
   */
  fileExtension?: string | null;
  /** Defines the approval status for documents. */
  salesApprovalStatus?: ApprovalStatus;
  /** Defines the approval status for documents. */
  schoolApprovalStatus?: ApprovalStatus;
  /**
   * The rejection reason to be displayed to the customer when their document has been rejected.
   * @example "Picture not readable"
   */
  rejectionReasonForCustomer?: string | null;
}

/** Additional elements describing the travel itinerary */
export interface EmbeddedResponse {
  bookings?: BookingResponse[] | null;
  travelLegs?: TravelLegResponse[] | null;
}

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
  Boys = "Boys",
  Girls = "Girls",
}

/** Represents a person's english details, such as name, date of birth, and contact information. */
export interface Latin {
  /** The person's first name. */
  firstName?: string | null;
  /** The person's middle name. */
  middleName?: string | null;
  /** The person's last name */
  lastName?: string | null;
  /** The state the person resides in. */
  state?: string | null;
  /** The city the person resides in. */
  city?: string | null;
  /** The street the person resides on. */
  street?: string | null;
  /** The postal code of the person's residency */
  postalCode?: string | null;
}

/** Mailing address details. */
export interface MailingAddress {
  /** Gets or sets the city of the mailing address. */
  city?: string | null;
  /** Gets or sets the country code of the mailing address. */
  countryCode?: string | null;
  /** Gets or sets the street of the mailing address. */
  street?: string | null;
  /** Gets or sets the postal code of the mailing address. */
  postalCode?: string | null;
  /** Gets or sets the region of the mailing address. */
  region?: string | null;
  /** Gets or sets the state of the mailing address. */
  state?: string | null;
}

/** Represents the market and language details of a customer. */
export interface MarketDetails {
  /** The market where the customer was contacted at */
  salesMarket?: string | null;
  /** The language the customer is being contacted in */
  languageCode?: string | null;
}

/** Required when itinerary is 'Private' Designed to capture the means of travel for a student in the context of private travel arrangements. */
export enum MeansOfTravel {
  ByThemselves = "ByThemselves",
  WithGuardian = "WithGuardian",
}

/** Medical Flags of the customer */
export enum MedicalFlags {
  DUST_ALLERGY = "DUST_ALLERGY",
  INSECT_ALLERGY = "INSECT_ALLERGY",
  NUTS_ALLERGY = "NUTS_ALLERGY",
  PENICILLIN_ALLERGY = "PENICILLIN_ALLERGY",
  PETS_ALLERGY = "PETS_ALLERGY",
  POLLEN_ALLERGY = "POLLEN_ALLERGY",
  VEGAN = "VEGAN",
  HALAL = "HALAL",
  GLUTEN_FREE = "GLUTEN_FREE",
  KOSHER = "KOSHER",
  VEGETARIAN = "VEGETARIAN",
  LACTOSE_FREE = "LACTOSE_FREE",
  DEAF = "DEAF",
  VISUALLY_IMPAIRED = "VISUALLY_IMPAIRED",
  USES_A_WHEELCHAIR = "USES_A_WHEELCHAIR",
  ASTHMATIC = "ASTHMATIC",
  EPILEPTIC = "EPILEPTIC",
  BED_WETTER = "BED_WETTER",
  DIABETIC = "DIABETIC",
  SMOKER = "SMOKER",
}

/** Item of medical information */
export interface MedicalsItem {
  /**
   * The category of medical issue. Possible values - allergies, dietary-needs, disabilities, others
   * @minLength 1
   * @example "dietary-needs"
   */
  category: string;
  /**
   * Additional information about the students' medical condition.
   * @example "more inforamtion"
   */
  note?: string | null;
  /**
   * Information what medication student carries (only present in other medical category).
   * @example "more inforamtion"
   */
  carriesMedication?: string | null;
  /**
   * The types of health issues or specific needs
   * @example ["Pollen","Penicillin"]
   */
  types?: string[] | null;
}

/** Request to update medical information of a student */
export interface MedicalsPutRequest {
  /** Students medical information indicators - all applicable should be selected */
  flags?: MedicalFlags[] | null;
  /**
   * Additional notes about students allergies
   * @example "Also allergic to hystamines"
   */
  allergyNotes?: string | null;
  /**
   * Additional notes about students diet
   * @example "Cannot eat Beef"
   */
  dietaryNotes?: string | null;
  /**
   * Additional notes about students disabilities
   * @example "Cannot walk long distances"
   */
  disabilityNotes?: string | null;
  /**
   * What medication student is carrying
   * @example "Paracetamol"
   */
  carriesMedication?: string | null;
  /**
   * Additional notes about students allergies
   * @example "Requires doctor checkup every 3 weeks"
   */
  otherMedicalNotes?: string | null;
}

/** Medical data of a single customer */
export interface MedicalsResponse {
  /**
   * Id of medical information
   * @minLength 1
   * @example "1a4e8516-9ac1-409d-bbea-1c29e20fcb99"
   */
  id: string;
  /**
   * Id of customer
   * @minLength 1
   * @example "8185e637-afba-4dc0-80f2-f9da4586d7d4"
   */
  customerId: string;
  /** Reasons which would block updates to any records of customer */
  updateBlockingReason?: UpdateBlockingReason;
  /** Elements of medical information */
  items?: MedicalsItem[] | null;
}

/** Post Travel Itinerary Request */
export interface PatchTravelItineraryRequest {
  /** Describes type of travel. */
  travelType: TravelItineraryType;
  /** Travel Itinerary Type classifies the itinerary based on its geographical scope, INTL - International, INTLDOM - International and Domestic, DOM - Domestic */
  type?: TravelType;
  /** Status of the resource in salesforce */
  status?: Status;
  /**
   * Required when travel type is 'Flight'. Alphanumeric code used to identify and access a specific record on an airline's reservation
   * @example "WWEABC"
   */
  recordLocator?: string | null;
  /**
   * Number of seats that are assigned to this Travel Itinerary.
   * @format int32
   * @example 10
   */
  seats?: number | null;
  /**
   * Description of the meeting point for groups.
   * @example "Terminal T1, piers A."
   */
  meetingPoint?: string | null;
  /**
   * The time you need to be at the departure point. Time is in UTC.
   * @example "13:30:00"
   */
  meetingTime?: string | null;
  /** Required when itinerary is 'Private' Designed to capture the means of travel for a student in the context of private travel arrangements. */
  meansOfTravel?: MeansOfTravel;
}

/** Post Travel Leg Request */
export interface PatchTravelLegRequest {
  /** Status of travel leg. */
  status?: string | null;
  /**
   * Travel number is REQUIRED only for 'Flight travel type.
   * @example "BA 417"
   */
  travelNumber?: string | null;
  /** Direction of the travel leg */
  direction?: TravelLegDirection;
  /** Departure Information gathered together. REQUIRED when direction is Return */
  departureInfo?: DepartureInfo;
  /** Arrival Information gathered together. REQUIRED when direction is Outbound */
  arrivalInfo?: ArrivalInfo;
  /**
   * Carrier is REQUIRED only for 'Flight' travel type.
   * @example "British Airways"
   */
  carrier?: string | null;
  /**
   * Additional information.
   * @example "This student will be arriving in London two days early and exploring the city with their parents. Then their parents will drop them directly at the school."
   */
  comments?: string | null;
}

/** Represents a person, including details such as name, date of birth, and contact information. */
export interface PersonDetails {
  /** The unique identifier for this person. */
  gender?: string | null;
  /** The country where this person was born. */
  birthCountry?: string | null;
  /** The city where this person was born. */
  birthCity?: string | null;
  /** The country code for the country where this person was born. */
  birthCountryCode?: string | null;
  /**
   * The date this person was born.
   * @format date
   */
  dateOfBirth?: string | null;
  /** The email address for this person. */
  email?: string | null;
  /** The email address to which invoices should be sent. */
  invoiceEmail?: string | null;
  /** Is this person an exchange student? */
  isExPax?: boolean | null;
  /** The country code for the country where this person holds citizenship. */
  nationalityCountryCode?: string | null;
  /**
   * The date this person's passport expires.
   * @format date
   */
  passportExpiryDate?: string | null;
  /** The passport number of the user */
  passportNumber?: string | null;
  optedOutOfEmail?: boolean | null;
  /** The phone number for this person's home. */
  homePhone?: string | null;
  /** The phone number for this person's mobile phone. */
  mobilePhone?: string | null;
  /** The phone number for this person's mobile phone while in school. */
  mobileInSchool?: string | null;
  /** The national ID of the person */
  nationalId?: string | null;
}

/** Represents a related contact. */
export interface RelatedContact {
  /**
   * The unique identifier for this related contact.
   * @format uuid
   */
  id?: string | null;
  /** The first name of the related contact. */
  firstName?: string | null;
  /** The last name of the related contact. */
  lastName?: string | null;
  /** The email address of the related contact. */
  email?: string | null;
  /** The phone number of the related contact. */
  phone?: string | null;
  /** The role of the related contact (e.g., Father, Mother, Child, Sibling, Guardian, Spouse/Civil partner, Other). */
  role?: string | null;
  /** Indicates whether the related contact is an emergency contact. */
  isEmergencyContact?: boolean | null;
}

/** A dto containing all the related contacts */
export interface RelatedContacts {
  /** The list of related contacts. */
  items?: RelatedContact[] | null;
}

/** Address details. */
export interface RequestAddresses {
  /** Mailing address details. */
  mailingAddress?: MailingAddress;
}

/** A request for the modification of a customer. */
export interface RequestCustomerChange {
  /** Gets or sets the first name of the customer. */
  firstName?: string | null;
  /** Gets or sets the last name of the customer. */
  lastName?: string | null;
  /** Gets or sets the middle name of the customer. */
  middleName?: string | null;
  /** Details about the person. */
  personDetails?: RequestPersonDetails;
  /** Details in English script. */
  latin?: RequestLatinDetails;
  /** Address details. */
  addresses?: RequestAddresses;
}

/** Details in English script. */
export interface RequestLatinDetails {
  /** Gets or sets the first name in English. */
  firstName?: string | null;
  /** Gets or sets the last name in English. */
  lastName?: string | null;
  /** Gets or sets the middle name in English. */
  middleName?: string | null;
  /** Gets or sets the city in English. */
  city?: string | null;
  /** Gets or sets the country code in English. */
  countryCode?: string | null;
  /** Gets or sets the street in English. */
  street?: string | null;
  /** Gets or sets the postal code in English. */
  postalCode?: string | null;
  /** Gets or sets the state in English. */
  state?: string | null;
}

/** Details about the person. */
export interface RequestPersonDetails {
  gender?: Gender;
  /** Gets or sets the birth city of the person. */
  birthCity?: string | null;
  /** Gets or sets the birth country of the person. */
  birthCountry?: string | null;
  /** Gets or sets the birth country code of the person. */
  birthCountryCode?: string | null;
  /**
   * Gets or sets the date of birth of the person.
   * @format date
   */
  dateOfBirth?: string | null;
  /**
   * Gets or sets the email of the person.
   * @format email
   */
  email?: string | null;
  /**
   * Gets or sets the invoice email of the person.
   * @format email
   */
  invoiceEmail?: string | null;
  /** Gets or sets the nationality country code of the person. */
  nationalityCountryCode?: string | null;
  /** Gets or sets the passport number of the person. */
  passportNumber?: string | null;
  /**
   * Gets or sets the passport expiry date of the person.
   * @format date
   */
  passportExpiryDate?: string | null;
  /** Gets or sets the home phone number of the person. */
  homePhone?: string | null;
  /** Gets or sets the mobile phone number of the person. */
  mobilePhone?: string | null;
  /** Gets or sets the mobile phone number in school of the person. */
  mobileInSchool?: string | null;
  /** Gets or sets the national ID of the person. */
  nationalId?: string | null;
}

/** Service Request creation response */
export interface ServiceRequestResponse {
  /**
   * Booking number related to the request
   * @minLength 1
   * @example "DEB232424"
   */
  bookingNumber: string;
  /**
   * Subject of the request
   * @minLength 1
   * @maxLength 255
   * @example "more inforamtion"
   */
  subject: string;
  /**
   * Details of the request. Use it to provide more context to the request
   * @maxLength 32000
   * @example "more inforamtion"
   */
  description?: string | null;
  /**
   * Type of request out of defined set of values. COURSE_EXTENSION - for all
   * cases when a student would like to extend the course. CUSTOMER_INFORMATION_UPDATE - any request related to updating
   * personal information. CUSTOMER_FINANCES - requests related to financial matters . CUSTOMER_TRAVEL - requests
   * related to travel details and travel arrangements. CUSTOMER_DOCUMENTS - requests related to correctness of the
   * documentation.
   */
  type: ServiceRequestType;
}

/**
 * Type of request out of defined set of values. COURSE_EXTENSION - for all
 * cases when a student would like to extend the course. CUSTOMER_INFORMATION_UPDATE - any request related to updating
 * personal information. CUSTOMER_FINANCES - requests related to financial matters . CUSTOMER_TRAVEL - requests
 * related to travel details and travel arrangements. CUSTOMER_DOCUMENTS - requests related to correctness of the
 * documentation.
 */
export enum ServiceRequestType {
  COURSE_EXTENSION = "COURSE_EXTENSION",
  CUSTOMER_INFORMATION_UPDATE = "CUSTOMER_INFORMATION_UPDATE",
  CUSTOMER_FINANCES = "CUSTOMER_FINANCES",
  CUSTOMER_TRAVEL = "CUSTOMER_TRAVEL",
  CUSTOMER_DOCUMENTS = "CUSTOMER_DOCUMENTS",
}

/** Status of the resource in salesforce */
export enum Status {
  Active = "Active",
  Cancelled = "Cancelled",
}

/** A list of travel itineraries */
export interface TravelItinerariesEnumerableResponse {
  items?: TravelItineraryResponse[] | null;
}

/** Bookings and Groups to link to the travel itinerary */
export interface TravelItineraryLinks {
  /**
   * Id of the group to connect to the travel itinerary (can be empty)
   * @example "ce98d1fc-4065-463d-a016-bbc94fbbc336"
   */
  groupId?: string | null;
  /**
   * Ids of the bookings to connect to the travel itinerary and group optionally
   * @example ["d16792e9-b10f-4f6c-a0d3-64e20fdfb1c5","8ff0e41a-57b8-4f65-9bd7-5b896f139d67"]
   */
  bookingIds?: string[] | null;
}

/** A single travel itinerary */
export interface TravelItineraryResponse {
  /** @example "39d5d7ec-caeb-4f52-bb7b-c075d451b89a" */
  id?: string | null;
  /** Status of the resource in salesforce */
  status?: Status;
  /** Describes type of travel. */
  travelType?: TravelItineraryType;
  /** Travel Itinerary Type classifies the itinerary based on its geographical scope, INTL - International, INTLDOM - International and Domestic, DOM - Domestic */
  type?: TravelType;
  /**
   * Required when travel type is 'Flight'. Alphanumeric code used to identify and access a specific record on an airline's reservation
   * @example "WWEABC"
   */
  recordLocator?: string | null;
  /**
   * Number of seats that are assigned to this Travel Itinerary.
   * @format int32
   * @example 10
   */
  seats?: number | null;
  /**
   * @format int32
   * @example 1
   */
  usedSeats?: number | null;
  /**
   * Description of the meeting point for groups.
   * @example "Terminal T1, piers A."
   */
  meetingPoint?: string | null;
  /**
   * The time you need to be at the departure point. Time is in UTC.
   * @example "13:30:00"
   */
  meetingTime?: string | null;
  /** Required when itinerary is 'Private' Designed to capture the means of travel for a student in the context of private travel arrangements. */
  meansOfTravel?: MeansOfTravel;
  /** Additional elements describing the travel itinerary */
  _embedded?: EmbeddedResponse;
}

/** Describes type of travel. */
export enum TravelItineraryType {
  Flight = "Flight",
  Train = "Train",
  Private = "Private",
  Coach = "Coach",
  Boat = "Boat",
}

/** Direction of the travel leg */
export enum TravelLegDirection {
  Outbound = "Outbound",
  Return = "Return",
}

/** A list of travel legs */
export interface TravelLegEnumerableResponse {
  items?: TravelLegResponse[] | null;
}

/** Travel Leg response item */
export interface TravelLegResponse {
  /**
   * Id of travel leg
   * @example "868b085e-e4f3-4ff8-8381-7fe8b57dfa6b"
   */
  id?: string | null;
  /**
   * The id of travel itinerary
   * @example "9cf92bcd-93d6-42f4-858a-da92cf7a60b0"
   */
  travelItineraryId?: string | null;
  /** Status of the resource in salesforce */
  status?: Status;
  /**
   * Travel number is REQUIRED only for 'Flight travel type.
   * @example "BA 417"
   */
  travelNumber?: string | null;
  /** Direction, picklist values indicates a 'Return', 'Outbound' transfer travel. */
  direction?: string | null;
  /** Departure Information gathered together. REQUIRED when direction is Return */
  departureInfo?: DepartureInfo;
  /** Arrival Information gathered together. REQUIRED when direction is Outbound */
  arrivalInfo?: ArrivalInfo;
  /**
   * Carrier is REQUIRED only for 'Flight' travel type.
   * @example "British Airways"
   */
  carrier?: string | null;
  /** Describes type of travel. */
  travelType?: TravelItineraryType;
  /**
   * Additional information.
   * @example "This student will be arriving in London two days early and exploring the city with their parents. Then their parents will drop them directly at the school."
   */
  comments?: string | null;
  origin?: string | null;
}

/** Travel Itinerary Type classifies the itinerary based on its geographical scope, INTL - International, INTLDOM - International and Domestic, DOM - Domestic */
export enum TravelType {
  INTL = "INTL",
  INTLDOM = "INTLDOM",
  DOM = "DOM",
}

/** Reasons which would block updates to any records of customer */
export enum UpdateBlockingReason {
  BookingStartingTooSoon = "BookingStartingTooSoon",
}

/** Http request for the creation of a new related contact. */
export interface UpdateRelatedContactRequest {
  /** Gets or sets the first name of the related contact. */
  firstName?: string | null;
  /** Gets or sets the last name of the related contact. */
  lastName?: string | null;
  /** Gets or sets the email of the related contact. */
  email?: string | null;
  /** Gets or sets the phone number of the related contact. */
  phone?: string | null;
  role?: CustomerRelationship;
  /** Gets or sets a value indicating whether the related contact is an emergency contact. */
  isEmergencyContact?: boolean | null;
}

/** Post Travel Itinerary Request */
export interface UpdateTravelItineraryRequest {
  /** Describes type of travel. */
  travelType: TravelItineraryType;
  /** Travel Itinerary Type classifies the itinerary based on its geographical scope, INTL - International, INTLDOM - International and Domestic, DOM - Domestic */
  type?: TravelType;
  /** Status of the resource in salesforce */
  status?: Status;
  /**
   * Required when travel type is 'Flight'. Alphanumeric code used to identify and access a specific record on an airline's reservation
   * @example "WWEABC"
   */
  recordLocator?: string | null;
  /**
   * Number of seats that are assigned to this Travel Itinerary.
   * @format int32
   * @example 10
   */
  seats?: number | null;
  /**
   * Description of the meeting point for groups.
   * @example "Terminal T1, piers A."
   */
  meetingPoint?: string | null;
  /**
   * The time you need to be at the departure point. Time is in UTC.
   * @example "13:30:00"
   */
  meetingTime?: string | null;
  /** Required when itinerary is 'Private' Designed to capture the means of travel for a student in the context of private travel arrangements. */
  meansOfTravel?: MeansOfTravel;
  /** Bookings and groups to connect to travel itinerary */
  links?: TravelItineraryLinks[] | null;
}

export interface ValidationProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors?: Record<string, string[]>;
  [key: string]: any;
}

export interface ValidationProblemResponse {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors?: Record<string, string[]>;
  efFlowId?: string | null;
  [key: string]: any;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://customer-service{environment}.{domain}.com/";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Customer Service API
 * @version 1.0.0
 * @baseUrl https://customer-service{environment}.{domain}.com/
 *
 * API for customer service related resources that should be used by applications needing to interact with customer
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  checklistItems = {
    /**
     * No description
     *
     * @tags ChecklistItems
     * @name ChecklistItemsList
     * @summary Get checklist items with document links filtered by Booking Id
     * @request GET:/checklist-items
     * @secure
     */
    checklistItemsList: (
      query: {
        /**
         * UUID
         * @example "15715c46-d2c4-4222-ae6e-984c45e50276"
         */
        bookingId: string;
        showInStudentPortal?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ChecklistItemsResponse, void>({
        path: `/checklist-items`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ChecklistItems
     * @name ChecklistItemsDetail
     * @summary Get a single checklist item by id
     * @request GET:/checklist-items/{id}
     * @secure
     */
    checklistItemsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ChecklistItem, void>({
        path: `/checklist-items/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  customers = {
    /**
     * No description
     *
     * @tags Customers
     * @name CustomersDetail
     * @summary Get single Customer data by Customer Id (all details)
     * @request GET:/customers/{customerId}
     * @secure
     */
    customersDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<Customer, ValidationProblemDetails | void>({
        path: `/customers/${customerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Works as a regular PATCH endpoint, updating only the fields that are provided in the request body. If a field is not provided, it will not be updated. If the provided value is null, it will be treated as if the field was not provided.
     *
     * @tags Customers
     * @name CustomersPartialUpdate
     * @summary Update single Customer data by Customer Id
     * @request PATCH:/customers/{customerId}
     * @secure
     */
    customersPartialUpdate: (customerId: string, data: RequestCustomerChange, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | void>({
        path: `/customers/${customerId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentLinks
     * @name DocumentLinksDetail
     * @summary Get document links of a customer with optional filter on bookingId and showInStudentPortal
     * @request GET:/customers/{customerId}/document-links
     * @secure
     */
    documentLinksDetail: (
      customerId: string,
      query?: {
        /**
         * Booking UUID
         * @example "06aa2c61-3c90-4b08-8d5f-a22853e36a22"
         */
        bookingId?: string;
        /** Indicate whether to return the ones that should be shown to student (default - returns all) */
        showInStudentPortal?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ChecklistItemsResponse, void>({
        path: `/customers/${customerId}/document-links`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medicals
     * @name MedicalsDetail
     * @summary Return medical information of a student.
     * @request GET:/customers/{customerId}/medicals
     * @secure
     */
    medicalsDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<MedicalsResponse, ValidationProblemResponse | void>({
        path: `/customers/${customerId}/medicals`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medicals
     * @name MedicalsUpdate
     * @summary Update medical information of student.
     * @request PUT:/customers/{customerId}/medicals
     * @secure
     */
    medicalsUpdate: (customerId: string, data: MedicalsPutRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemResponse | void>({
        path: `/customers/${customerId}/medicals`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RelatedContacts
     * @name RelatedContactsDetail
     * @request GET:/customers/{customerId}/related-contacts
     * @secure
     */
    relatedContactsDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<RelatedContacts, void | ValidationProblemDetails>({
        path: `/customers/${customerId}/related-contacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RelatedContacts
     * @name RelatedContactsCreate
     * @request POST:/customers/{customerId}/related-contacts
     * @secure
     */
    relatedContactsCreate: (customerId: string, data: CreateRelatedContactRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | void>({
        path: `/customers/${customerId}/related-contacts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Works as a regular PATCH endpoint, updating only the fields that are provided in the request body. If a field is not provided, it will not be updated. If the provided value is null, it will be treated as if the field was not provided.
     *
     * @tags RelatedContacts
     * @name RelatedContactsPartialUpdate
     * @request PATCH:/customers/{customerId}/related-contacts/{relatedContactId}
     * @secure
     */
    relatedContactsPartialUpdate: (
      customerId: string,
      relatedContactId: string,
      data: UpdateRelatedContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemDetails | void>({
        path: `/customers/${customerId}/related-contacts/${relatedContactId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  documentLinks = {
    /**
     * No description
     *
     * @tags DocumentLinks
     * @name DocumentLinksCreate
     * @summary Create link to document in Juno
     * @request POST:/document-links
     * @secure
     */
    documentLinksCreate: (data: CreateDocumentLinkRequest, params: RequestParams = {}) =>
      this.request<CreateDocumentLinkResponse, void>({
        path: `/document-links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  medicals = {
    /**
     * No description
     *
     * @tags Medicals
     * @name MedicalsDetail
     * @summary Return Medical information using id.
     * @request GET:/medicals/{id}
     * @secure
     */
    medicalsDetail: (id: string, params: RequestParams = {}) =>
      this.request<MedicalsResponse, ValidationProblemResponse | void>({
        path: `/medicals/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  serviceRequests = {
    /**
     * No description
     *
     * @tags ServiceRequests
     * @name ServiceRequestsCreate
     * @summary Create new service request to open a case in Juno on behalf of the token holder.
     * @request POST:/service-requests
     * @secure
     */
    serviceRequestsCreate: (data: CreateServiceRequestRequest, params: RequestParams = {}) =>
      this.request<ServiceRequestResponse, void>({
        path: `/service-requests`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceRequests
     * @name ServiceRequestsDetail
     * @summary Get a single checklist item by id
     * @request GET:/service-requests/{id}
     * @secure
     */
    serviceRequestsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ServiceRequestResponse, void>({
        path: `/service-requests/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  travelItineraries = {
    /**
     * No description
     *
     * @tags Travel
     * @name TravelItinerariesList
     * @summary Return multiple travel itineraries based on query
     * @request GET:/travel-itineraries
     * @secure
     */
    travelItinerariesList: (
      query?: {
        /** List of Travel Itinerary Ids separated by commas. */
        travelItineraryIds?: string[];
        /** List of Booking Ids separated by commas. */
        bookingIds?: string[];
        /** List of Travel Group Ids separated by commas. */
        groupIds?: string[];
        /** List of Record Locators(PNRs) separated by commas. */
        recordLocators?: string[];
        /** List of Booking Numbers separated by commas. */
        bookingNumbers?: string[];
        /** List of Origin values separated by commas. */
        origins?: string[];
        /** List of Status values separated by commas. */
        statuses?: string[];
        /** List of objects to embed separated by commas. */
        embed?: string[];
        /**
         * Start index of items to be retrieved
         * @format int32
         * @example 0
         */
        offset?: number;
        /**
         * Count of items to be retrieved. Parameter is within the range of 1-20
         * @format int32
         * @example 10
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<TravelItinerariesEnumerableResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name TravelItinerariesCreate
     * @summary Create a single travel itinerary
     * @request POST:/travel-itineraries
     * @secure
     */
    travelItinerariesCreate: (data: CreateTravelItineraryRequest, params: RequestParams = {}) =>
      this.request<CreateTravelResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name TravelItinerariesDetail
     * @summary Returns a single travel itinerary
     * @request GET:/travel-itineraries/{travelItineraryId}
     * @secure
     */
    travelItinerariesDetail: (
      travelItineraryId: string,
      query?: {
        /** List of objects to embed separated by commas. */
        embed?: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<TravelItineraryResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name TravelItinerariesUpdate
     * @summary Update single Travel Itinerary by Travel Itinerary Id and its linked bookings and groups.
     * @request PUT:/travel-itineraries/{travelItineraryId}
     * @secure
     */
    travelItinerariesUpdate: (
      travelItineraryId: string,
      data: UpdateTravelItineraryRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name TravelItinerariesPartialUpdate
     * @summary DEPRECATED use PUT /travel-itineraries - Patch single Travel Itinerary by Travel Itinerary Id.
     * @request PATCH:/travel-itineraries/{travelItineraryId}
     * @deprecated
     * @secure
     */
    travelItinerariesPartialUpdate: (
      travelItineraryId: string,
      data: PatchTravelItineraryRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name BookingsDelete
     * @summary DEPRECATED use PUT /travel-itineraries - Remove connection from booking to travel itinerary
     * @request DELETE:/travel-itineraries/{travelItineraryId}/bookings/{bookingId}
     * @deprecated
     * @secure
     */
    bookingsDelete: (travelItineraryId: string, bookingId: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/bookings/${bookingId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name BookingsUpdate
     * @summary DEPRECATED use PUT /travel-itineraries - Connect a single booking to travel itinerary
     * @request PUT:/travel-itineraries/{travelItineraryId}/bookings/{bookingId}
     * @deprecated
     * @secure
     */
    bookingsUpdate: (travelItineraryId: string, bookingId: string, params: RequestParams = {}) =>
      this.request<CreateTravelResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/bookings/${bookingId}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name GroupsDelete
     * @summary DEPRECATED use PUT /travel-itineraries - Remove group from travel itineraries - also removes all booking links that are part of the group
     * @request DELETE:/travel-itineraries/{travelItineraryId}/groups/{groupId}
     * @deprecated
     * @secure
     */
    groupsDelete: (travelItineraryId: string, groupId: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/groups/${groupId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Travel
     * @name PutSingle
     * @summary DEPRECATED use PUT /travel-itineraries - Connect multiple bookings that are part of group to a travel itinerary
     * @request PUT:/travel-itineraries/{travelItineraryId}/groups/{groupId}
     * @deprecated
     * @secure
     */
    putSingle: (
      travelItineraryId: string,
      groupId: string,
      data: CreateGroupTravelItineraryRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateTravelResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/groups/${groupId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelLeg
     * @name TravelLegsDetail
     * @summary Get list of Travel Legs by Travel Itinerary Id
     * @request GET:/travel-itineraries/{travelItineraryId}/travel-legs
     * @secure
     */
    travelLegsDetail: (travelItineraryId: string, params: RequestParams = {}) =>
      this.request<TravelLegEnumerableResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/travel-legs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelLeg
     * @name TravelLegsCreate
     * @summary Create Travel Leg for a Travel Itinerary.
     * @request POST:/travel-itineraries/{travelItineraryId}/travel-legs
     * @secure
     */
    travelLegsCreate: (travelItineraryId: string, data: CreateTravelLegRequest, params: RequestParams = {}) =>
      this.request<CreateTravelResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/travel-legs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelLeg
     * @name TravelLegsDelete
     * @summary Delete all Travel Legs related to Travel Itinerary
     * @request DELETE:/travel-itineraries/{travelItineraryId}/travel-legs
     * @secure
     */
    travelLegsDelete: (travelItineraryId: string, params: RequestParams = {}) =>
      this.request<DeleteTravelLegsResponse, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/travel-legs`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelLeg
     * @name TravelLegsPartialUpdate
     * @summary Update Travel Leg by Travel Itin and Travel Leg Id
     * @request PATCH:/travel-itineraries/{travelItineraryId}/travel-legs/{travelLegId}
     * @secure
     */
    travelLegsPartialUpdate: (
      travelItineraryId: string,
      travelLegId: string,
      data: PatchTravelLegRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemResponse | void>({
        path: `/travel-itineraries/${travelItineraryId}/travel-legs/${travelLegId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
