import React, { RefObject, useEffect, useRef } from "react";
import Surface from "../Surface/Surface";
import { Text } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { PaymentStatus, Quote } from "../../Common/Types";
import { PaymentDetails } from "../../Common/Helpers/PaymentHelper";

interface ThankYouProps {
  paymentDetails: PaymentDetails;
  quote: Quote;
}

const ThankYou: React.FC<ThankYouProps> = ({ paymentDetails, quote }) => {
  const { labels } = useDatasources();
  const [showPaymentLink, setShowPaymentLink] = React.useState(false);
  const thankYou = useRef(null);

  const scrollToElement = (myElementRef: RefObject<Element>) => {
    if (myElementRef?.current) {
      myElementRef?.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    scrollToElement(thankYou);
  }, [thankYou, thankYou?.current]);

  useEffect(() => {
    setShowPaymentLink(paymentDetails.status === PaymentStatus.ReservationSuccess);
  }, [paymentDetails]);

  return (
    <div data-testid="studentDetails-form-thankyou">
      <Surface>
        <div className="a-gap flex flex-col" ref={thankYou}>
          <div className="a-gap-sm flex flex-col justify-between">
            <div className="flex-shrink-0">
              <Text variant="heading-5-bold">{labels[LabelKey.studentDetailsFullUpgradeThankYouMessage]}</Text>
            </div>
            <div className="text-sm">
              <Text variant="paragraph-body">
                {labels[LabelKey.studentDetailsFullUpgradeThankYouMessageDescription]}
              </Text>
            </div>
          </div>
        </div>
        <div className={"a-gap-sm sm:a-gap mt-8 flex flex-col sm:flex-row sm:justify-between"}>
          {showPaymentLink && (
            <input
              className="max-h-12 w-full cursor-pointer rounded-full bg-black px-6 py-3 text-center text-lg font-normal leading-6 text-white"
              type="button"
              value={labels[LabelKey.studentDetailsFullUpgradeThankYouMessageCompletePayment]}
              onClick={() => (window.location.href = `/payment/${quote.id}`)}
            />
          )}
          <input
            className={`max-h-12 ${showPaymentLink ? "w-full" : "w-auto"} cursor-pointer rounded-full border border-ink-black bg-white px-6 py-3 text-center text-lg font-normal leading-6 text-ink-black`}
            type="button"
            value={labels[LabelKey.studentDetailsFullUpgradeThankYouMessageGoToReservation]}
            onClick={() => (window.location.href = `/quote/${quote.id}`)}
          />
        </div>
      </Surface>
    </div>
  );
};

export default ThankYou;
