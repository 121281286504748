import React, { createContext, useContext, useEffect, useState, PropsWithChildren } from "react";
import { getStoryblokApi, useStoryblok } from "@storyblok/react";
import { useSessionContext } from "../SessionContext/SessionContext";
import { StringIndexableDictionary } from "../../Common/Types";
import { AppConfig } from "../../AppConfig";
import { DatasourceEntry } from "../../Common/StoryblokTypes";
import { ISbStoryData } from "@storyblok/react/dist/types/types";

interface ContextValue {
  [key: string]: StringIndexableDictionary<string>;
}

interface DatasourcesToFetch {
  [key: string]: {
    datasourceName: string;
    dimension: string;
    returnDimensionValue: boolean;
    fallbackToValue: boolean;
    fallbackDimension?: string;
    data?: Array<DatasourceEntry>;
  };
}

const defaultContextValue = {
  labels: {},
  featureSettings: {},
  programs: {},
  countries: {},
  activeLanguage: {},
};
const StoryblokContext = createContext<ContextValue>(defaultContextValue);

const Dimensions = {
  default: "default",
  atlas: "atlas",
  efcom: "efcom",
};

const Datasources = {
  languageMappings: "language-mappings",
  labels: "labels",
  featureSettings: "feature-settings",
  programMappings: "program-mappings",
  countries: "countries",
  atlasfallbackDestinationMappings: "atlas-fallback-destination-mappings",
  nationality: "nationality-mappings",
};

const StoryblokContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const StoryblokClient = getStoryblokApi();
  const storyblokCVParam = Math.floor(Date.now()); // milisecond Epoch Unix Timestamp / 1000 sec == ~16 min
  const { session } = useSessionContext();
  const [storyblokContext, setStoryblokContext] = useState<ContextValue>(defaultContextValue);

  const getDatasourceEntries = async (datasource: string, dimension?: string) =>
    StoryblokClient.get(AppConfig.storyBlok.datasourceEntries, {
      page: 1,
      per_page: 300,
      datasource,
      dimension,
      cv: storyblokCVParam,
    });

  const fetchDatasources = async (datasourcesToFetch: DatasourcesToFetch) => {
    for (const [key, value] of Object.entries(datasourcesToFetch)) {
      if (value.fallbackDimension)
        datasourcesToFetch[`${key}Fallback`] = {
          ...value,
          dimension: value.fallbackDimension,
          fallbackDimension: "",
        };
    }

    const allDatasources = await Promise.all(
      Object.values(datasourcesToFetch).map((datasource) =>
        getDatasourceEntries(datasource.datasourceName, datasource.dimension)
      )
    );

    Object.keys(datasourcesToFetch).forEach(
      (key, index) => (datasourcesToFetch[key].data = allDatasources[index].data.datasource_entries)
    );
    const fallbackDatasources = { ...datasourcesToFetch };
    const getFallbackEntry = (key: string, name: string) => {
      return fallbackDatasources[`${key}Fallback`].data?.find((entry) => entry.name === name)?.dimension_value ?? "";
    };

    const allDatasourcesData = Object.fromEntries(
      Object.entries(datasourcesToFetch).map(([key, value]) => [
        key,
        value.data?.reduce(
          (contextData: StringIndexableDictionary<string>, entry: DatasourceEntry) => ({
            ...contextData,
            [entry.name]: value.returnDimensionValue
              ? entry.dimension_value
                ? entry.dimension_value
                : value.fallbackDimension
                  ? getFallbackEntry(key, entry.name)
                    ? getFallbackEntry(key, entry.name)
                    : entry.value
                  : entry.value
              : entry.value,
          }),
          {}
        ),
      ])
    );

    return allDatasourcesData;
  };

  const userLanguage =
    session.user.market && session.user.languageCode
      ? `${session.user.market}_${session.user.languageCode}`
      : `${AppConfig.user.defaultMarket}_${AppConfig.user.defaultLanguage}`;

  useEffect(() => {
    if (!session.user) return;
    const setStoryblokContent = async () => {
      const staticDatasources = {
        languagesDefault: {
          datasourceName: Datasources.languageMappings,
          dimension: Dimensions.default,
          returnDimensionValue: true,
          fallbackToValue: true,
        },
        languagesAtlas: {
          datasourceName: Datasources.languageMappings,
          dimension: Dimensions.atlas,
          returnDimensionValue: true,
          fallbackToValue: true,
        },
        languagesEfcom: {
          datasourceName: Datasources.languageMappings,
          dimension: Dimensions.efcom,
          returnDimensionValue: true,
          fallbackToValue: true,
        },
        programs: {
          datasourceName: Datasources.programMappings,
          dimension: Dimensions.efcom,
          returnDimensionValue: true,
          fallbackToValue: false,
        },
        countries: {
          datasourceName: Datasources.countries,
          dimension: Dimensions.default,
          returnDimensionValue: false,
          fallbackToValue: false,
        },
        atlasfallbackDestinationMappings: {
          datasourceName: Datasources.atlasfallbackDestinationMappings,
          dimension: Dimensions.default,
          returnDimensionValue: false,
          fallbackToValue: false,
        },
        nationalities: {
          datasourceName: Datasources.nationality,
          dimension: Dimensions.default,
          returnDimensionValue: true,
          fallbackToValue: false,
        },
      };

      const staticDatasourcesData = await fetchDatasources(staticDatasources);

      const defaultLanguage =
        staticDatasourcesData.languagesDefault?.[userLanguage] ??
        staticDatasourcesData.languagesDefault?.[AppConfig.user.defaultLanguage] ??
        AppConfig.content.defaultLanguage;

      const activeLanguage: StringIndexableDictionary<string> = {
        default: defaultLanguage,
        atlas:
          staticDatasourcesData.languagesAtlas?.[userLanguage] ??
          staticDatasourcesData.languagesAtlas?.[AppConfig.user.defaultLanguage] ??
          AppConfig.atlas.defaultLanguage,
        efcom:
          staticDatasourcesData.languagesEfcom?.[userLanguage] ??
          staticDatasourcesData.languagesEfcom?.[AppConfig.user.defaultLanguage] ??
          AppConfig.efcom.defaultMarket,
        defaultFallback: defaultLanguage.split("-")[0],
      };

      const dynamicDatasources = {
        labels: {
          datasourceName: Datasources.labels,
          dimension: activeLanguage.default,
          fallbackDimension: activeLanguage.defaultFallback,
          returnDimensionValue: true,
          fallbackToValue: true,
        },
        featureSettings: {
          datasourceName: Datasources.featureSettings,
          dimension: activeLanguage.default,
          returnDimensionValue: true,
          fallbackToValue: true,
        },
      };

      const dynamicDatasourcesData = await fetchDatasources(dynamicDatasources);
      setStoryblokContext({
        ...staticDatasourcesData,
        ...dynamicDatasourcesData,
        activeLanguage: activeLanguage,
      });
    };
    setStoryblokContent();
  }, [userLanguage]);

  // Can't let internal components render without language!
  if (!Object.keys(storyblokContext.activeLanguage).length) {
    return;
  }

  return <StoryblokContext.Provider value={storyblokContext}>{children}</StoryblokContext.Provider>;
};

const useDatasources = () => {
  const context = useContext(StoryblokContext);

  if (!context) {
    throw new Error("useDatasources must be used within StoryblokContextProvider!");
  }

  return context;
};

export const useStoryblokSlug = (
  slug: string,
  activeLanguage: StringIndexableDictionary<string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ISbStoryData<any> => {
  return useStoryblok(slug, {
    version: AppConfig.storyBlok.version,
    language: activeLanguage?.default ?? "",
    fallback_lang: activeLanguage.defaultFallback.replace("-", "_"),
  });
};

export { StoryblokContextProvider, useDatasources };
