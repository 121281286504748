import React, { ReactNode } from "react";

interface SurfaceProps {
  children?: ReactNode;
  borderRadius?: "all" | "top" | "bottom" | "none";
  zIndex?: number;
  className?: string;
}

const Surface: React.FC<SurfaceProps> = ({ children, className = "", borderRadius = "all", zIndex = 3 }) => {
  const radius = {
    none: "",
    all: "rounded-lg",
    top: "rounded-t-lg",
    bottom: "rounded-b-lg",
  };

  return (
    <div className={`${radius[borderRadius]} a-card-px a-card-py z-[${zIndex}] bg-white shadow ${className}`}>
      {children}
    </div>
  );
};

export default Surface;
