import React, { useEffect, useState } from "react";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import moment from "moment";
import { TRUE } from "../../Common/Constants";
import "./StudentDetailsFullUpgrade.scss";
import "intl-tel-input/build/css/intlTelInput.css";
import StudentBasicDetails, { StudentBasicDetailsForm } from "./StudentBasicDetails";
import MedicalDetails from "./MedicalDetails";
import MultiStepForm from "./MultiStepForm";
import ThankYou from "./ThankYou";
import { PaymentDetails } from "../../Common/Helpers/PaymentHelper";
import { Quote } from "../../Common/Types";
import ParentDetails from "./ParentDetails";

interface StudentDetailsFullUpgradeProps {
  paymentDetails: PaymentDetails;
  quote: Quote;
  onComplete: () => void;
}

const StudentDetailsFullUpgrade: React.FC<StudentDetailsFullUpgradeProps> = ({ paymentDetails, quote, onComplete }) => {
  const { featureSettings } = useDatasources();
  const [needParentDetails, setNeedParentDetails] = useState(true);
  const [needMedicalDetails, setNeedMedicalDetails] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(undefined);

  const handleIfNeedParentDetails = (dateOfBirth: string) => {
    const userAge = moment(new Date()).diff(moment(dateOfBirth), "years");
    if (userAge < 18 && featureSettings.isParentDetailsEnabled === TRUE) {
      setNeedParentDetails(true);
    } else {
      setNeedParentDetails(false);
    }
  };

  useEffect(() => {
    if (dateOfBirth) {
      handleIfNeedParentDetails(dateOfBirth);
    }
    if (featureSettings.isMedicalDetailsEnabled === TRUE) {
      setNeedMedicalDetails(true);
    } else {
      setNeedMedicalDetails(false);
    }
  }, [dateOfBirth, featureSettings]);

  return (
    <MultiStepForm
      id={"studentDetailsForm"}
      data-testid="studentDetails-form"
      onComplete={onComplete}
      onFormValuesChange={(values) => {
        const data = values.data as StudentBasicDetailsForm;
        if (data) {
          setDateOfBirth(data.dateOfBirth);
        }
      }}
    >
      <StudentBasicDetails quote={quote} />
      {needParentDetails && <ParentDetails quote={quote} />}
      {needMedicalDetails && <MedicalDetails quote={quote} />}
      <MultiStepForm.Complete>
        <ThankYou paymentDetails={paymentDetails} quote={quote} />
      </MultiStepForm.Complete>
    </MultiStepForm>
  );
};

export default StudentDetailsFullUpgrade;
