import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AppConfig } from "../../AppConfig";
import { AUTH_TOKEN_REGEX, SUCCESS } from "../../Common/Constants";
import "../../App.scss";
import { fetchRetry } from "../../Common/services/FetchRetry";
import { Loading } from "@ilc-technology/luik";
import { AuthorizationFailedErrorPage, TokenErrorPage } from "../../components/ErrorPages/ErrorPages";
import { ErrorCode, ErrorDetails, Relationship } from "../../Common/Types";
import { useSearchParams } from "react-router-dom";
import { DraftFunction, useImmer } from "use-immer";
import { convertToErrorDetails, returnJsonOrThrowError, logError } from "../../Common/services/ErrorService";
import { getRumClient } from "../../Common/services/AwsRumService";
import { jwtDecode } from "jwt-decode";

export interface SalesUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  photoUrl: string;
  title: string;
  aboutMe: string;
}

export interface User {
  accountId: string;
  languageCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  invoiceEmail: string;
  gender: string;
  dateOfBirth: string;
  nationalityCountryCode: string;
  birthCountry: string;
  passportNumber: string;
  market: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  mobilePhone: string;
  isFullUpdate: boolean;
  firstNameLatin: string;
  middleNameLatin: string;
  lastNameLatin: string;
  relatedContact: RelatedContact | undefined;
}

export interface RelatedContact {
  id: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: Relationship;
  isEmergencyContact: boolean;
}

export interface Opportunity {
  id: string;
  salesOfficeCode: string;
  telephone: Telephone;
}

interface Session {
  accessToken: string;
  jwtAccessToken: string;
  salesUser: SalesUser;
  user: User;
  opportunity: Opportunity;
  accountUuid: string;
}

interface JwtPayload {
  extension_EFILC_UUID: string;
}

export type SessionResponse = {
  accessToken: string;
  user: User;
  opportunity: Opportunity;
  salesUser: SalesUser;
};

export type UserSessionResponse = {
  status: string;
  message?: string;
  session: SessionResponse;
  jwtAccessToken: string;
};

interface ContextValue {
  session: Session;
  updateSession: (arg: Session | DraftFunction<Session>) => void;
  language: string;
}

interface SalesOffice {
  poseidonCode: string;
  telephone: Telephone;
}

export type UpdateAccountResponse = {
  isSuccessful: boolean;
  error?: ErrorDetails;
};

export interface Telephone {
  generalNumber: string;
  generalNumberFormattedForMobile: string;
}

const SessionContext = createContext<ContextValue | null>(null);

interface SessionContextProviderProps {
  children: ReactNode;
}

const SessionContextProvider: React.FC<SessionContextProviderProps> = ({ children }) => {
  const [session, updateSession] = useImmer({} as Session);
  const [language, setLanguage] = useState(navigator.language);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState<ErrorDetails | undefined>(undefined);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [noTokenError, setNoTokenError] = useState<ErrorDetails | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const removeTokenFromQuery = () => {
    if (searchParams.has("token")) {
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const token =
      document.location.search.match(AUTH_TOKEN_REGEX)?.[1] ??
      localStorage.getItem(AppConfig.sessionStorageKeys.authToken);

    if (!token) {
      setNoTokenError({
        code: ErrorCode.NoTokenProvidedOrInvalidFormat,
      });
    } else {
      localStorage.setItem(AppConfig.sessionStorageKeys.authToken, token);
    }

    if (!isAuthorized && token) {
      fetchRetry(`${AppConfig.api.efPlanner}/authorizations`, {
        method: "POST",
        mode: "cors",
        redirect: "manual",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => returnJsonOrThrowError<UserSessionResponse>(response))
        .then((result) => {
          if (result.status === SUCCESS) {
            let decodedToken: JwtPayload = {
              extension_EFILC_UUID: "unknown",
            };
            try {
              decodedToken = jwtDecode<JwtPayload>(result.jwtAccessToken);
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (err: unknown) {
              /* empty */
            }
            updateSession({
              opportunity: result.session.opportunity,
              user: result.session.user,
              salesUser: result.session.salesUser,
              accessToken: result.session.accessToken,
              jwtAccessToken: result.jwtAccessToken,
              accountUuid: decodedToken.extension_EFILC_UUID,
            });
            getRumClient()?.addSessionAttributes({
              opportunityUuid: result.session.opportunity.id,
              accountUuid: decodedToken.extension_EFILC_UUID,
            });
            setLanguage(result.session.user.languageCode ?? navigator.language);
            sessionStorage.setItem(AppConfig.sessionStorageKeys.jwtToken, result.jwtAccessToken);
            removeTokenFromQuery();
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
            setNoTokenError({
              code: ErrorCode.AuthenticationFailed,
              details: {
                additionalDetails: `Authorization result status not successful: ${result.status}`,
              },
            });
            console.error(`Authorization result status not successful: ${result.status}`);
          }
        })
        .catch((ex) => {
          const errorDetails = convertToErrorDetails(ex, ErrorCode.AuthenticationFailed);
          logError(errorDetails);
          setAuthError(errorDetails);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let code: string = session?.opportunity?.salesOfficeCode;
    if (!code || code?.length < 2) {
      code = session?.user?.market;
    }
    if (!code || code?.length < 2) {
      return;
    }
    const countryCode: string = code.substring(0, 2).toLowerCase();
    fetchRetry(`${AppConfig.api.centralApi}/common/office/v2/getsalesoffices/${countryCode}`)
      .then((response) => returnJsonOrThrowError<Array<SalesOffice>>(response))
      .then((result: Array<SalesOffice>) => {
        const salesOffice = result.find(
          (entry: SalesOffice) => entry.poseidonCode === session.opportunity.salesOfficeCode
        );
        const marketSalesOffice = result.find((entry: SalesOffice) => entry.poseidonCode === session.user.market);
        if (salesOffice || marketSalesOffice) {
          const telephone = salesOffice?.telephone ?? marketSalesOffice?.telephone;
          updateSession((s) => {
            s.opportunity.telephone = telephone!;
          });
        } else {
          logError(
            new ErrorDetails({
              code: ErrorCode.SalesOfficesDataNotFound,
              details: {
                additionalDetails: `SalesOfficeCode=${session?.opportunity?.salesOfficeCode}, MarketCode=${session?.user?.market}`,
              },
            })
          );
        }
      })
      .catch((ex) => {
        const errorDetails = convertToErrorDetails(ex, ErrorCode.SalesOfficesLoadFailed);
        logError(errorDetails);
      });
  }, [session?.user?.market, session?.opportunity?.salesOfficeCode]);

  if (isLoading) {
    return (
      <div className="center">
        <Loading className="loading" size="lg" intent="black" />
      </div>
    );
  }

  if (authError) {
    return <AuthorizationFailedErrorPage errorDetails={authError} />;
  }

  if (noTokenError) {
    return <TokenErrorPage errorDetails={noTokenError} />;
  }

  return (
    <SessionContext.Provider
      value={{
        session,
        updateSession,
        language,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

function useSessionContext() {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSessionContext must be used within SessionContextProvider!");
  }

  return context;
}

export { SessionContextProvider, useSessionContext };
