import { Divider, Text } from "@ilc-technology/luik";
import React, { useState } from "react";

interface MedicalDetailsSectionHeaderProps {
  id: string;
  title: string;
  description?: string;
  checked: boolean | undefined;
  yesText: string;
  noText: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  hideSeparator?: boolean;
  onMedicalDecisionChanged?: (newValue: boolean) => void;
}

const MedicalDetailsSectionHeader: React.FC<MedicalDetailsSectionHeaderProps> = ({
  id,
  title,
  checked,
  description,
  yesText,
  noText,
  children,
  onMedicalDecisionChanged,
  isDisabled = false,
  hideSeparator = false,
}) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(checked);

  return (
    <div className="a-gap-sm flex flex-col">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex-shrink-0">
          <Text variant="heading-5-bold">{title}</Text>
        </div>
        <div className="flex items-center space-x-4">
          <label className="flex items-center gap-1">
            <input
              checked={isChecked === true}
              type="radio"
              name={id}
              disabled={isDisabled}
              value={isChecked === true ? "yes" : "no"}
              onChange={() => {
                setIsChecked(true);
                if (onMedicalDecisionChanged) {
                  onMedicalDecisionChanged(true);
                }
              }}
              data-testid={`${id}-yes-radio`}
            />
            <span>{yesText}</span>
          </label>
          <label className="flex items-center gap-1">
            <input
              checked={isChecked === false}
              type="radio"
              name={id}
              disabled={isDisabled}
              value={isChecked === false ? "yes" : "no"}
              onChange={() => {
                setIsChecked(false);
                if (onMedicalDecisionChanged) {
                  onMedicalDecisionChanged(false);
                }
              }}
              data-testid={`${id}-no-radio`}
            />
            <span>{noText}</span>
          </label>
        </div>
      </div>
      {description && (
        <div className="text-sm">
          <Text variant="paragraph-body">{description}</Text>
        </div>
      )}
      {isChecked === true && children}
      {!hideSeparator && <Divider light />}
    </div>
  );
};

export default MedicalDetailsSectionHeader;
